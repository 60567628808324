import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import './MobiusStrip.css'

const MobiusStrip2 = (props) => {
  const mountRef = useRef(null);
  const widthScaleRef = useRef(1.2);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000);

    // Function to compute widthScale based on screen width
    function computeWidthScale(screenWidth) {
      const minScreenWidth = 320;
      const maxScreenWidth = 1920;
      const minWidthScale = 0.8;
      const maxWidthScale = 1.2;

      const clampedWidth = Math.min(
        Math.max(screenWidth, minScreenWidth),
        maxScreenWidth
      );

      const widthScale =
        maxWidthScale -
        ((clampedWidth - minScreenWidth) / (maxScreenWidth - minScreenWidth)) *
          (maxWidthScale - minWidthScale);

      return widthScale;
    }

    if (mountRef.current) {
      mountRef.current.appendChild(renderer.domElement);
      renderer.setSize(
        mountRef.current.offsetWidth,
        mountRef.current.offsetHeight
      );
      const width = mountRef.current.offsetWidth;
      widthScaleRef.current = computeWidthScale(width);
    }

    const onWindowResize = () => {
      if (mountRef.current) {
        const width = mountRef.current.offsetWidth;
        const height = mountRef.current.offsetHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        widthScaleRef.current = computeWidthScale(width);
      }
      adjustCamera();
    };
    window.addEventListener("resize", onWindowResize);
    // Create background starfield
    function createStarfield() {
      const starCount = 20000;
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(starCount * 3);
      const colors = new Float32Array(starCount * 3);
      const sizes = new Float32Array(starCount);

      for (let i = 0; i < starCount; i++) {
        const radius = 50 + Math.random() * 100;
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.acos(2 * Math.random() - 1);

        positions[i * 3] = radius * Math.sin(phi) * Math.cos(theta);
        positions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta);
        positions[i * 3 + 2] = radius * Math.cos(phi);

        const blueIntensity = 0.5 + Math.random() * 0.5;
        colors[i * 3] = 0.7 + Math.random() * 0.3;
        colors[i * 3 + 1] = 0.7 + Math.random() * 0.3;
        colors[i * 3 + 2] = blueIntensity;

        sizes[i] = Math.random() * 2;
      }

      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
      geometry.setAttribute("size", new THREE.BufferAttribute(sizes, 1));

      const material = new THREE.PointsMaterial({
        size: 0.15,
        vertexColors: true,
        transparent: true,
        opacity: 0.8,
        blending: THREE.AdditiveBlending,
      });

      return new THREE.Points(geometry, material);
    }

    const starfield = createStarfield();
    scene.add(starfield);

    const particlesCount = 5000;
    const particles = new THREE.BufferGeometry();
    const initialParticleSize = 0.005; // Smaller size during gathering
    const finalParticleSize = 0.02; // Original size after gathering

    const particleMaterial = new THREE.PointsMaterial({
      size: 0.02,
      vertexColors: true,
      transparent: true,
      opacity: 0.8,
      blending: THREE.AdditiveBlending,
    });

    const positions = new Float32Array(particlesCount * 3);
    const colors = new Float32Array(particlesCount * 3);
    const particleData = [];
    const targetPositions = [];

    function getInfinityPoint(t, offset = 0) {
      const scale = 4;
      const widthScale = widthScaleRef.current;
      const thickness = 0.2;
      const angle = t * Math.PI * 2;
      const x =
        widthScale * ((scale * Math.cos(angle)) / (1 + Math.sin(angle) ** 2));
      const y =
        (scale * Math.sin(angle) * Math.cos(angle)) /
        (1 + Math.sin(angle) ** 2);

      const dx = -scale * Math.sin(angle);
      const dy = scale * Math.cos(angle);
      const length = Math.sqrt(dx ** 2 + dy ** 2);
      const nx = (dx / length) * thickness;
      const ny = (dy / length) * thickness;
      const circleAngle = offset * Math.PI * 2;

      return {
        x: x + nx * Math.cos(circleAngle),
        y: y + ny * Math.sin(circleAngle),
        z: 0,
      };
    }

    function hexToRgbNormalized(hex) {
      const bigint = parseInt(hex.replace("#", ""), 16);
      const r = ((bigint >> 16) & 255) / 255;
      const g = ((bigint >> 8) & 255) / 255;
      const b = (bigint & 255) / 255;
      return { r, g, b };
    }

    const color1 = hexToRgbNormalized("#F07E7E");
    const color2 = hexToRgbNormalized("#EEE3E3");

    for (let i = 0; i < particlesCount; i++) {
      const t = Math.random();
      const offset = Math.random();
      const speed = 0.05 + Math.random() * 0.1;

      // Generate random initial positions
      const initialPosition = {
        x: (Math.random() - 0.5) * 20,
        y: (Math.random() - 0.5) * 20,
        z: (Math.random() - 0.5) * 20,
      };

      // Calculate target positions on the infinity loop
      const targetPoint = getInfinityPoint(t, offset);

      // Store data for each particle
      particleData.push({
        t: t,
        offset: offset,
        speed: speed,
        initialPosition: initialPosition,
        targetPosition: targetPoint,
      });

      // Set initial positions to random positions
      positions[i * 3] = initialPosition.x;
      positions[i * 3 + 1] = initialPosition.y;
      positions[i * 3 + 2] = initialPosition.z;

      // Store target positions
      targetPositions.push(targetPoint);

      // Assign colors
      if (Math.random() < 0.5) {
        colors[i * 3] = color1.r;
        colors[i * 3 + 1] = color1.g;
        colors[i * 3 + 2] = color1.b;
      } else {
        colors[i * 3] = color2.r;
        colors[i * 3 + 1] = color2.g;
        colors[i * 3 + 2] = color2.b;
      }
    }

    particles.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    particles.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    const particleSystem = new THREE.Points(particles, particleMaterial);
    scene.add(particleSystem);

    const degToRad = (degrees) => (degrees * Math.PI) / 180;

        
    function adjustCamera() {
      const width = mountRef.current.offsetWidth;
      const height = mountRef.current.offsetHeight;
      const aspectRatio = width / height;
      camera.position.z = 4;
      camera.aspect = aspectRatio;
      camera.updateProjectionMatrix();
    }

    adjustCamera();


    adjustCamera();

    // Variables for gathering effect
    let gatheringProgress = 0;
    const gatheringDuration = 3; // in seconds
    let lastTime = performance.now();

    function animate() {
      requestAnimationFrame(animate);

      const currentTime = performance.now();
      const deltaTime = (currentTime - lastTime) / 1000; // in seconds
      lastTime = currentTime;

      starfield.rotation.y += 0.0001;
      starfield.rotation.x += 0.0001;

      const positions = particles.attributes.position.array;

      // Update gathering progress
      if (gatheringProgress < 1) {
        gatheringProgress += deltaTime / gatheringDuration;
        if (gatheringProgress > 1) gatheringProgress = 1;
      }

      // Update particle size during gathering
      if (gatheringProgress < 1) {
        particleMaterial.size =
          initialParticleSize +
          (finalParticleSize - initialParticleSize) * gatheringProgress;
      } else {
        particleMaterial.size = finalParticleSize;
      }

      for (let i = 0; i < particlesCount; i++) {
        const particle = particleData[i];

        if (gatheringProgress < 1) {
          // Interpolate positions from initial to target
          positions[i * 3] =
            particle.initialPosition.x +
            (particle.targetPosition.x - particle.initialPosition.x) *
              gatheringProgress;
          positions[i * 3 + 1] =
            particle.initialPosition.y +
            (particle.targetPosition.y - particle.initialPosition.y) *
              gatheringProgress;
          positions[i * 3 + 2] =
            particle.initialPosition.z +
            (particle.targetPosition.z - particle.initialPosition.z) *
              gatheringProgress;
        } else {
          // After gathering, animate particles along the infinity loop
          particle.offset += particle.speed * 0.003;
          if (particle.offset > 1) particle.offset -= 1;

          particle.t += 0.0002;
          if (particle.t > 1) particle.t -= 1;

          const point = getInfinityPoint(particle.t, particle.offset);
          positions[i * 3] = point.x;
          positions[i * 3 + 1] = point.y;
          positions[i * 3 + 2] = point.z;
        }
      }

      particles.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
    }
    animate();

    return () => {
      window.removeEventListener("resize", onWindowResize);
      if (mountRef.current) mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      <div ref={mountRef} className="mobius-container" />
      <div className="overlay-container">
        {props.children}
      </div>
    </div>
  );
};

export default MobiusStrip2;
