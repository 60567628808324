import Header from '../components/Header'
import MobiusStrip from '../components/MobiusStrip';
import './AboutPage.css';

const AboutPage = () => {

    return (
        <div>
            <Header></Header>
            <div className="about-container">
                <div className="mission-container" >
                    <div className="mission-text" id="mission-text">
                        <h4>Our mission</h4>
                        <p>To transfrom textile waste into <br /> resources through advanced sorting <br /> technology, promoting a circular <br /> economy<span>.</span></p>
                    </div>
                    <div className='us-mobius'>
                        <p>순환 경제는 마치 <span>뫼비우스의 띠</span>와 같습니다. <br className="delete" />
                            끝없이 이어지는 하나의 선처럼 자원이 계속해서 <br className="delete" />
                            순환할 수 있어야 합니다. 하지만 매년 수 많은 <br className="delete" />
                            폐원단이 소각되며, 이 순환 고리가 끊어지고 있습니다.
                        </p>
                    </div>
                </div>
                <div className="us-container">
                    <div className="us-mobius2">
                        <p className="br">
                            <span>우리의 기술은 <span className="bold">끊어진 고리를 다시 이어줍니다. </span> </span><br className="delete" />
                            폐원단을 선별해 재생 플라스틱으로 활용함으로써 자원의 순환을 지속시키고, <br className="delete" />
                            사용된 자원이 다시 새로운 가치를 창출할 수 있게 합니다.
                        </p>
                    </div>
                    <div className='us-about'>
                        <h4>About us</h4>
                        <div className='us-about-container'>
                            <div className='us-about-profile-container'>
                                <img src="./img/profile/JMS.png" />
                                <h1 className="us-about-profile-name">주민성 Minseong Joo</h1>
                                <h1 className="us-about-profile-position">CEO</h1>
                            </div>
                            <div className='us-about-profile-container'>
                                <img src="./img/profile/KSJ2.png" />
                                <h1 className="us-about-profile-name">김성준 Seongjun Kim</h1>
                                <h1 className="us-about-profile-position">CTO</h1>
                            </div>
                            <div className='us-about-profile-container'>
                                <img src="./img/profile/KYB.png" />
                                <h1 className="us-about-profile-name">김윤범 Yunbeom Kim</h1>
                                <h1 className="us-about-profile-position">COO</h1>
                            </div>
                            <div className='us-about-profile-container'>
                                <img src="./img/profile/YSM.png" />
                                <h1 className="us-about-profile-name">유승민 Sungmin You</h1>
                                <h1 className="us-about-profile-position">AI/ML Developer</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AboutPage;