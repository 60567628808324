import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Hls from 'hls.js';
import './Header.css';
import SlideMenu from './SlideMenu';
import MobiusStrip from './MobiusStrip';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const navbar = document.querySelector('.header-component-nav');
    const showNavbarHeight = 0; // 메뉴바가 나타날 스크롤 높이(px)

    const handleScroll = () => {
      if (navbar && window.innerWidth < 992) {
        if (window.scrollY > showNavbarHeight) {
          navbar.classList.add('fixed'); // 메뉴바 표시
        } else {
          navbar.classList.remove('fixed'); // 메뉴바 숨기기
        }
      }
    };

    // 메뉴 열림/닫힘 상태에 따른 설정
    if (navbar) {
      if (isMenuOpen) {
        navbar.classList.add('open');
        if (window.innerWidth < 992) {
          document.body.style.overflow = 'hidden';
        }
      } else {
        navbar.classList.remove('open');
        document.body.style.overflow = 'auto';
      }
    }
    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);
    // cleanup 함수
    return () => {
      document.body.style.overflow = 'auto'; // 컴포넌트가 언마운트될 때 스크롤 복원
      window.removeEventListener('scroll', handleScroll); // 스크롤 이벤트 제거
    };
  }, [isMenuOpen]);


  useEffect(() => {

    const navbar = document.querySelector('.header-component-nav');
    const showNavbarHeight = 0; // 메뉴바가 나타날 스크롤 높이(px)

    window.addEventListener('scroll', () => {
      if (navbar && window.innerWidth < 992) { // navbar가 존재하는지 확인
        if (window.scrollY > showNavbarHeight) {
          navbar.classList.add('fixed'); // 메뉴바 표시
        } else {
          navbar.classList.remove('fixed'); // 메뉴바 숨기기
        }
      }
    });
  }, []);

  useEffect(() => {
    const videoSources = [
      { id: 'video1', src: './img/header-video/header-video.m3u8' },
    ];

    videoSources.forEach(({ id, src }) => {
      const video = document.getElementById(id);

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.muted = true;
          video.play().catch((error) => {
            console.log(`Autoplay was prevented for ${id}:`, error);
          });
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
        video.muted = true;
        video.addEventListener('loadedmetadata', () => {
          video.play().catch((error) => {
            console.log(`Autoplay was prevented for ${id}:`, error);
          });
        });
      }
    });
  }, []);

  return (
    <header className="header-component">
      <div className='header-component-text-section'>
        <MobiusStrip>
          <div className='header-component-nav'>
            <div className="header-component-logo">
              <Link to="/"><img className="header-component-logo-img" src="./img/icons/SortexLogo.png" alt="Sortex Logo" /></Link>
            </div>
            <div className="hamburger2" onClick={toggleMenu}>
              <svg id="hamburger2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 256 256">
                <g fill="#999999" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}>
                  <g transform="scale(5.12,5.12)">
                    <path d="M5,8c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175zM5,23c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175zM5,38c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175z" />
                  </g>
                </g>
              </svg>
            </div>
            <div className='header-component-nav-list'>
              <ul>
                <li><Link to="/about">About us</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
          </div>
        </MobiusStrip>
        <SlideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}></SlideMenu>

        <div id="header-video2">
          <div className='mobius-container'>
          </div>
        </div>
      </div>
    </header >
  );
};

export default Header;
