import Header from '../components/Header';
import './PickupPage.css';
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const PickupPage = () => {
    const [name, setName] = useState('');
    const [number, setnumber] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [inquiry, setInquiry] = useState('');
    const [errors, setErrors] = useState({ name: '', number: '', address: '', inquiry: '' });

    const loadDaumPostcode = () => {
        return new Promise((resolve) => {
            if (window.daum && window.daum.Postcode) {
                resolve(window.daum.Postcode);
            } else {
                const script = document.createElement("script");
                script.src = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
                script.onload = () => resolve(window.daum.Postcode);
                document.body.appendChild(script);
            }
        });
    };

    const openAddressSearch = async () => {
        const Postcode = await loadDaumPostcode();
        new Postcode({
            oncomplete: function (data) {
                setAddress(data.address); // 기본 주소 설정
            },
        }).open();
    };

    const handleAddress2Change = (e) => {
        setAddress2(e.target.value); // address2 상태만 업데이트
    };

    const validateForm = () => {
        let nameError = '';
        let numberError = '';
        let addressError = '';
        let address2Error = '';
        let inquiryError = '';

        const nameRegex = /^[가-힣a-zA-Z]{2,}$/;
        const numberRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if (!nameRegex.test(name)) {
            nameError = '이름은 두 글자 이상의 한글 또는 영어로 입력해주세요.';
        }

        if (!numberRegex.test(number)) {
            numberError = '연락처를 다시 확인해주세요.';
        }

        if (address.length < 1) {
            addressError = '주소를 입력하세요.';
        }

        if (address2.trim().length === 0) {
            address2Error = '상세주소를 입력하세요.';
        }

        if (inquiry.length < 2) {
            inquiryError = '문의 사항은 두 글자 이상이어야 합니다.';
        }

        setErrors({ name: nameError, number: numberError, address: addressError, address2: address2Error, inquiry: inquiryError });

        return !nameError && !numberError && !addressError && !address2Error && !inquiryError;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const fullAddress = `${address} ${address2}`; // address와 address2를 합쳐 최종 주소 생성
            try {
                await addDoc(collection(db, 'pickupRequest'), {
                    name,
                    number,
                    address: fullAddress,
                    inquiry,
                });
                alert("메세지 전송 완료");
                window.location.reload(); // 페이지 새로고침
            } catch (error) {
                console.error("Error adding document: ", error);
                alert("메시지 전송에 실패했습니다. 다시 시도해주세요.");
            }
        } else {
            alert("입력 내용을 확인해주세요.");
        }
    };

    return (
        <div>
            <Header />
            <div className='form-container'>
                <div className='contact-body'>
                    <div className='pickup-title-box'>
                        <h1>폐원단 무료 수거 신청</h1>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='form-input-container'>
                            <label className="form-label" htmlFor="name">이름 / Your name</label>
                            <input type="text" className="form-input" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                            {errors.name && <p className="required">{errors.name}</p>}
                        </div>
                        <div className='form-input-container'>
                            <label className="form-label" htmlFor="number">연락처 / Your number</label>
                            <input type="text" className="form-input" id="number" value={number} onChange={(e) => setnumber(e.target.value)} />
                            {errors.number && <p className="required">{errors.number}</p>}
                        </div>
                        <div className='form-input-container'>
                            <label className="form-label" htmlFor="address">주소 / Your address</label>
                            <div className='address-button-container'>
                                <input type="text" className="form-input" id="address" value={address} placeholder='주소를 검색하세요.' required readOnly />
                                <button type="button" className="form-input" onClick={openAddressSearch}>주소 검색</button>
                            </div>
                            <input type="text" className="form-input" id="address2" value={address2} onChange={handleAddress2Change} placeholder='상세주소를 입력하세요.' />
                            {errors.address && <p className="required">{errors.address}</p>}
                            {errors.address2 && <p className="required">{errors.address2}</p>}

                        </div>
                        <div className='form-input-container'>
                            <label className="form-label" htmlFor="inquiry">특이사항 / Additional requirement</label>
                            <textarea id="inquiry" className="form-input" value={inquiry} maxLength="300" onChange={(e) => setInquiry(e.target.value)}></textarea>
                            <div className="char-limit">{inquiry.length} / 300</div>
                            {errors.inquiry && <p className="required">{errors.inquiry}</p>}
                        </div>
                        <div className='button-flex'>
                            <button type="submit" className="submit-btn">보내기</button>
                        </div>
                    </form>

                    <div className="pickup-text">
                        수거 소요 시간은 최대 n시간 입니다.<br />
                        추가 문의 sortex2488@gmail.com<br />
                        서울특별시 동대문구 회기로85, 슈펙스경영관 KAIST-SK 임팩트비즈니스센터
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PickupPage;
