import React, { useState } from "react";
import "./SlideMenu.css";

const SlideMenu = ({ isOpen, toggleMenu }) => {

    
    return (
        <>
            {isOpen && (
                <div className="slide-overlay" onClick={toggleMenu}></div>
            )}
            <div className={`slide-menu ${isOpen ? "open" : ""}`}>
                <div className="slide-menu-content">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="pickupRequest">폐원단 수거 신청</a></li>
                        <li><a href="about">about</a></li>
                        <li><a href="contact">contact us</a></li>
                        {/* 추가 메뉴 항목 */}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default SlideMenu;
