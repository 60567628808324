import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hls from "hls.js";
import "./Homepage.css";
import MobiusStrip2 from "../components/MobiusStrip2";
import SlideMenu from "../components/SlideMenu";


const Homepage = () => {
  const videoSources = [
    { id: 'video1', src: './img/header-video/header-video.m3u8' },
    { id: 'video2', src: './img/textile-sort-video/textile-sort-master.m3u8' },
    { id: 'video3', src: './img/color-classifier-video/color-classifier-master.m3u8' },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  useEffect(() => {
    const navbar = document.querySelector('.header-top');
    const showNavbarHeight = 0;

    const handleScroll = () => {
      if (navbar && window.innerWidth < 992) {
        if (window.scrollY > showNavbarHeight) {
          navbar.classList.add('fixed');
        } else {
          navbar.classList.remove('fixed');
        }
      }
    };
    if (navbar) {
      if (isMenuOpen) {
        navbar.classList.add('open');
        if (window.innerWidth < 992) {
          document.body.style.overflow = 'hidden';
        }
      } else {
        navbar.classList.remove('open');
        document.body.style.overflow = 'auto';
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMenuOpen]);

  useEffect(() => {

    videoSources.forEach(({ id, src }) => {
      const video = document.getElementById(id);

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.muted = true;
          video.play().catch((error) => {
            console.log(`Autoplay was prevented for ${id}:`, error);
          });
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src;
        video.muted = true;
        video.addEventListener("loadedmetadata", () => {
          video.play().catch((error) => {
            console.log(`Autoplay was prevented for ${id}:`, error);
          });
        });
      }
    });
  }, []);

  return (
    <div className="sortex-container">
      <section className="header-section">
        <div className="header-text-section">
          <MobiusStrip2>
            <div className="header-top">
              <div className="sortex-logo">
                <img src="./img/icons/SortexLogo.png" alt="Sortex Logo" />
              </div>
              <div className="hamburger" onClick={toggleMenu}>
                <svg
                  id="hamburger"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="50"
                  height="50"
                  viewBox="0 0 256 256"
                >
                  <g
                    fill="#999999"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M5,8c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175zM5,23c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175zM5,38c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175z" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="header-list-con">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About us</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </MobiusStrip2>
          <SlideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}></SlideMenu>
        </div>
      </section>

      <div className="body-section">
        <div className="button-section">
          <div className="body-size">
            <div className="button-section-text">
              <h4 className="button-section-title">
                사라지는 자원, 타오르는{" "}
                <span className="button-section-title-red">기회</span>
              </h4>
              <p className="button-section-p">
                <span className="button-section-p-bold">전체 원단의 20%</span>는
                자투리로 버려지며, 그 양은 세계적으로 연간{" "}
                <span className="button-section-p-bold">2,500만 톤</span>에
                달합니다.
              </p>
              <p className="button-section-p">
                하지만 이러한 폐원단은 대부분 재활용 되지 않고 소각됩니다.
              </p>
            </div>
            <div className="button-section-imgContainer">
              <img
                src="./img/truckClothes-compressed.jpg"
                className="button-section-img"
                alt="폐원단1"
              />
              <img
                src="./img/waste1.jpg"
                className="button-section-img"
                alt="폐원단2"
              />
              <img
                src="./img/waste2.jpg"
                className="button-section-img"
                alt="폐원단3"
              />
            </div>
            <div className="collect-button-container">
              <Link to={process.env.PUBLIC_URL + "/PickupRequest"}>
                <button className="collect-button">
                  폐원단 무료 수거 신청
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="solution-section">
          <div className="body-size">
            <div className="solution-img-container">
              <h4>Sortex Solution</h4>
              <img src="./img/Main.png" alt="메인 이미지" />
            </div>
            <div className="v-line"></div>
            <p className="solution-section-p">
              Sortex는 버려지는 폐원단을 수거 및 선별하여 <br />
              자원화합니다. 특히 고순도 폴리에스터를 선별하여 <br />
              재생 플라스틱으로 자원화하는 혁신적인 기술을 통해 <br />{" "}
              <span className="solution-section-p-bold">
                폐원단 문제와 플라스틱 문제를 동시에 해결합니다.
              </span>
            </p>
          </div>
        </div>

        <div className="technology-section">
          <div className="body-size">
            <h4 className="technology-title-h4">Our technology</h4>
            <div className="technology-video-container">
              <div className="technology-video2-container">
                <video
                  className="technology-video"
                  id="video2"
                  muted
                  autoPlay
                  playsInline
                  loop
                ></video>
              </div>
              <div className="h-line"></div>
              <div className="technology-video2-container">
                <video
                  className="technology-video"
                  id="video3"
                  muted
                  autoPlay
                  playsInline
                  loop
                ></video>
              </div>
            </div>

            <div className="v-line"></div>
            <div>
              <p className="technology-video-p">
                <span>머신러닝 기술로 폐원단을 색상과 소재별로 정확하게</span>{" "}
                <br /> <span>선별</span>하여, 고객의 다양한 필요에 맞게
                자원화합니다. <br /> 우리의 기술로 분류된 폐원단은 새로운 용도로{" "}
                <br /> 재탄생하며, 순환경제의 고리를 함께 만들어갑니다.
              </p>
            </div>
          </div>

          <div className="box-container">
            <Link id="box2" className="box-navigator" to="/about">
              <div>
                <h1>Our Mission</h1>
                <p>머신러닝 기반 선별 기술로
                  <br /> 폐원단을 자원으로 전환하여
                  <br /> 순환 경제를 촉진합니다.
                </p>
              </div>
            </Link>
            <Link id="box2" className="box-navigator" to="/about">
              <div  >
                <h1>Team Sortex</h1>
                <p>우리 팀은 기술로 환경문제를
                  <br /> 해결하며, 자원의 지속가능한
                  <br /> 순환을 위해 노력합니다.
                </p>
              </div>
            </Link>
            <Link id="box3" className="box-navigator" to="/contact">
              <div >
                <h1>More Information?</h1>
                <p>Sortex에 대해 궁금하신 점을
                  <br /> 얼마든지 물어보세요.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
