import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCpzR-QtkfBXQkpaFAPkGsoFvByRz27NQg",
  authDomain: "sortex-e91b1.firebaseapp.com",
  projectId: "sortex-e91b1",
  storageBucket: "sortex-e91b1.firebasestorage.app",
  messagingSenderId: "125920376682",
  appId: "1:125920376682:web:b8b72f0e3fe9946d7e1713",
  measurementId: "G-EH3VXQXF9G"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);