import Header from '../components/Header'
import './ContactPage.css'
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const ContactPage = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [inquiry, setInquiry] = useState('');
    const [errors, setErrors] = useState({ name: '', email: '', inquiry: '' });

    const validateForm = () => {
        let nameError = '';
        let emailError = '';
        let inquiryError = '';

        const nameRegex = /^[가-힣a-zA-Z]{2,}$/;
        const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

        if (!nameRegex.test(name)) {
            nameError = '이름은 두 글자 이상의 한글 또는 영어로 입력해주세요.';
        }

        if (!emailRegex.test(email)) {
            emailError = '이메일을 다시 확인해주세요.';
        }

        if (inquiry.length < 2) {
            inquiryError = '문의 사항은 두 글자 이상이어야 합니다.';
        }

        setErrors({ name: nameError, email: emailError, inquiry: inquiryError });

        return !nameError && !emailError && !inquiryError;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await addDoc(collection(db, 'contacts'), {
                    name,
                    email,
                    inquiry,
                });
                alert("메세지 전송 완료");
                window.location.reload(); // 페이지 새로고침
            } catch (error) {
                console.error("Error adding document: ", error);
                alert("메시지 전송에 실패했습니다. 다시 시도해주세요.");
            }
        } else {
            alert("입력 내용을 확인해주세요.");
        }
    };

    return (
        <div>
            <Header></Header>
            <div className='contact-form-container'>
                <div className='contact-body'>
                    <div className="contact-title-box">
                        <h1>Contact us</h1>
                        <p> 문의하실 내용을 아래에 입력해주세요.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='contact-form-input-container'>
                            <label className="contact-form-label" htmlFor="name">이름 / Your name</label>
                            <input type="text" className="contact-form-input" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                            {errors.name && <p className="contact-required">{errors.name}</p>}
                        </div>
                        <div className='contact-form-input-container'>
                            <label className="contact-form-label" htmlFor="email">이메일 / Your email</label>
                            <input type="text" className="contact-form-input" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            {errors.email && <p className="contact-required">{errors.email}</p>}
                        </div>
                        <div className='contact-form-input-container'>
                            <label className="contact-form-label" htmlFor="inquiry">문의 사항 / Your inquiry</label>
                            <textarea id="contact-inquiry" className="contact-form-input" value={inquiry} maxLength="300" onChange={(e) => setInquiry(e.target.value)} required></textarea>
                            <div className="contact-required"></div>
                            <div className="contact-char-limit">{inquiry.length} / 300</div>
                        </div>
                        <button type="submit" className="contact-submit-btn">문의하기</button>
                    </form>

                    <div className="contact-text">
                        추가 문의 sortex2488@gmail.com<br />
                        서울특별시 동대문구 회기로85, 슈펙스경영관 KAIST-SK 임팩트비즈니스센터
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ContactPage;