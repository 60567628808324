import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer";
import PickupPage from "./pages/PickupPage";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path={process.env.PUBLIC_URL + "/"} element={<Homepage />}></Route>
        <Route exact path={process.env.PUBLIC_URL + "/about"} element={<AboutPage />}></Route>
        <Route exact path={process.env.PUBLIC_URL + "/contact"} element={<ContactPage />}></Route>
        <Route exact path={process.env.PUBLIC_URL + "/pickupRequest"} element={<PickupPage />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
